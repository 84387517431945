import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  Col,
  Row,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import { changeActiveSidebarItem, changeActiveSidebarHeader } from "../../actions/navigation.js";

const Card = (props) => {
    const ref = useRef(null);
    const sectionData = props.sectionData;
    const parentLink = `/${sectionData.id}`;
    const link='';
    const location = useLocation()

    useEffect(() => {
        const observerMargin = Math.floor(window.innerHeight / 2);
        // const observerMargin = Math.floor(window.innerHeight / 3);
        const observerConfig = {
            rootMargin: `-${window.innerHeight % 2 === 0 ? observerMargin - 1 : observerMargin}px 0px -${observerMargin}px 0px`,
          };
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.target.id !== props.activeItem && entry.isIntersecting) {
                    props.dispatch(changeActiveSidebarHeader(parentLink))
                    props.dispatch(changeActiveSidebarItem(link));
                }
            });
        };
        const observer = new IntersectionObserver(handleIntersection, observerConfig);
        if (ref.current) observer.observe(ref.current);

        if (ref.current && parentLink === location.pathname) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                // block: 'center',
                // inline: 'center'
            })
            props.dispatch(changeActiveSidebarHeader(parentLink))
            props.dispatch(changeActiveSidebarItem(link));
        }

        return () => {
            if (ref.current) observer.disconnect()
        }
    }, [ref])

    return (
        <h2 ref={ref} key={sectionData.id} id={sectionData.id} className="mb-4">{sectionData.title}</h2>
    )
}

Card.propTypes = {
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string.isRequired,
    sectionData: PropTypes.any.isRequired,
}

function mapStateToProps(store) {
    return {
        activeItem: store.navigation.activeItem,
    };
}

export default connect(mapStateToProps)(Card);
