import IbkrLogo from "../components/Icons/IbkrLogo.js";
import TdaLogo from "../components/Icons/TdaLogo.js";
import TigerLogo from "../components/Icons/TigerLogo.js";
import MoomooLogo from "../components/Icons/MoomooLogo";

import GeminiLogo from "../components/Icons/GeminiLogo.js";
import KucoinLogo from "../components/Icons/KucoinLogo.js";
import BlockfiLogo from "../components/Icons/BlockfiLogo.js";
import HodlnautLogo from "../components/Icons/HodlnautLogo.js";
import CakeDefiLogo from "../components/Icons/CakeDefiLogo.js";

import DigitalOceanLogo from "../components/Icons/DigitalOceanLogo.js";
import LumiHealthLogo from "../components/Icons/LumiHealthLogo.js";
import ShopeeLogo from "../components/Icons/ShopeeLogo.js";

const RawData = [
    {
        id: 'brokers',
        title: 'Brokers',
        icon: "fa fa-institution",
        items: [
            {
                id: 'interactivebrokers',
                title: 'Interactive Brokers',
                logo: IbkrLogo,
                description: <span>Earn up to USD 200 in cash and up to USD 1000 in IBKR stock with this exclusive promo code. More details <a href='https://www.interactivebrokers.com/en/index.php?f=45764' target="_blank" rel="noreferrer">here</a>.</span>,
                code: 'referralsasia',
                link: 'https://ibkr.com/referral/referralsasia',
            },
            // {
            //     id: 'tdameritrade',
            //     title: 'TD Ameritrade',
            //     logo: TdaLogo,
            //     description: <span>Earn up to USD 200 and up to USD 1000 in IBKR stock by using this referral code. More details <a href='https://www.interactivebrokers.com/en/index.php?f=45764' target="_blank" rel="noreferrer">here</a>.</span>,
            //     code: 'referralsasia',
            //     link: 'https://www.interactivebrokers.com/en/index.php?f=45764',
            //     email: true
            // },
            {
                id: 'tigerbrokers',
                title: 'Tiger Brokers',
                logo: TigerLogo,
                description: <span>Celebrate the year of the tiger with this exclusive promo code. Enjoy free shares from Tesla, Apple, Grab, Twitter and more!  More details <a href='https://www.tigerbrokers.com.sg/market/lpv1?invite=TIGERYEAR' target="_blank" rel="noreferrer">here</a>.</span>,
                code: 'TIGERYEAR',
                link: 'https://www.tigersecurities.com/signup?invite=TIGERYEAR',
            },
            // {
            //     id: 'moomoo',
            //     title: 'Moomoo',
            //     logo: MoomooLogo,
            //     description: <span>Earn up to USD 200 and up to USD 1000 in IBKR stock by using this referral code. More details <a href='https://www.interactivebrokers.com/en/index.php?f=45764' target="_blank" rel="noreferrer">here</a>.</span>,
            //     code: 'referralsasia',
            //     link: 'https://www.interactivebrokers.com/en/index.php?f=45764',
            // }
        ],
    },
    {
        id: 'crypto',
        title: 'Crypto',
        icon: "fa fa-btc",
        items: [
            {
                id: 'gemini',
                title: 'Gemini',
                logo: GeminiLogo,
                description: <span>Earn USD 10 when you buy or sell USD 100 or more. More details <a href='https://www.gemini.com/refer-a-friend' target="_blank" rel="noreferrer">here</a>.</span>,
                code: '67wrv6xhe',
                link: 'https://www.gemini.com/share/67wrv6xhe',
            },
            {
                id: 'kucoin',
                title: 'Kucoin',
                logo: KucoinLogo,
                description: <span>Unlock mystery prizes when you sign up for Kucoin with this promo code. More details <a href='https://www.kucoin.com/referral?rcode=rJ1Z1D9' target="_blank" rel="noreferrer">here</a>.</span>,
                code: 'rJ1Z1D9',
                link: 'https://www.kucoin.com/ucenter/signup?rcode=rJ1Z1D9',
            },
            {
                id: 'blockfi',
                title: 'Blockfi',
                logo: BlockfiLogo,
                description: <span>Receive cashback when you sign up for BlockFi with this promo code. More details <a href='https://blockfi.com/promotions/?ref=76b5df3f' target="_blank" rel="noreferrer">here</a>.</span>,
                code: '76b5df3f',
                link: 'https://app.blockfi.com/signup/?ref=76b5df3f',
            },
            {
                id: 'hodlnaut',
                title: 'Hodlnaut',
                logo: HodlnautLogo,
                description: <span>Earn USD 30 when you deposit USD 1000 into Hodlnaut. More details <a href='https://www.hodlnaut.com/referral-program' target="_blank" rel="noreferrer">here</a>.</span>,
                code: 'sbpweLhah',
                link: 'https://www.hodlnaut.com/join/sbpweLhah',
            },
            {
                id: 'cakedefi',
                title: 'Cake DEFI',
                logo: CakeDefiLogo,
                description: <span>Earn up to USD 40 when you sign up with Cake DEFI with this referral code. More details <a href='https://app.cakedefi.com/referral' target="_blank" rel="noreferrer">here</a>.</span>,
                code: '920502',
                link: 'https://cakedefi.com/?ref=920502',
            }
        ],
    },
    {
        id: 'ecommerce',
        title: 'E-Commerce',
        icon: "fa fa-shopping-cart",
        items: [
            {
                id: 'shopee',
                title: 'Shopee',
                logo: ShopeeLogo,
                description: <span>Enjoy SGD 10 off your first order with this referral code. More details <a href="https://help.shopee.sg/portal/article/76501-[Referrals]-How-does-the-Refer-A-Friend-programme-work%3F#:~:text=Refer%20A%20Friend%20is%20Shopee's,the%20use%20of%20referral%20code" target="_blank" rel="noreferrer">here</a>.</span>,
                code: 'REF-SAMD88',
                link: 'https://shp.ee/56rqn49?smtt=0.0.10',
            }
        ],
    },
    {
        id: 'tech',
        title: 'Tech',
        icon: "fa fa-terminal",
        items: [
            {
                id: 'digitalocean',
                title: 'DigitalOcean',
                logo: DigitalOceanLogo,
                description: <span>Enjoy USD 100 of DigitalOcean credits when you sign up with this referral code. More details <a href='https://www.digitalocean.com/referral-program' target="_blank" rel="noreferrer">here</a>.</span>,
                code: 'ac011aa478c6',
                link: 'https://m.do.co/c/ac011aa478c6',
            },
            {
                id: 'lumihealth',
                title: 'LumiHealth',
                logo: LumiHealthLogo,
                description: <span>Earn 10,000 LumiPoints when you sign up for LumiHealth with this referral code. More details <a href='https://www.lumihealth.sg/faq#referral-programme' target="_blank" rel="noreferrer">here</a>.</span>,
                code: 'c15db61fb4e46b95',
                link: 'https://referral.lumihealth.sg/invite/c15db61fb4e46b95',
            }
        ]
    },
];

const Data = RawData
    .sort((a, b) => a.id < b.id)
    .map((x) => ({
        ...x,
        items: x.items.sort((a, b) => a.id < b.id),
    }))

export default Data;
