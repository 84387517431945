import React from 'react';

class Logo extends React.Component {
  render() {
    return (
        <span dangerouslySetInnerHTML={{__html: `
        <?xml version="1.0"?>
        <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 100 100" xml:space="preserve"><g><defs><circle id="SVGID_61_" cx="50" cy="50" r="40"/></defs><clipPath id="SVGID_2_"><use xlink:href="#SVGID_61_" overflow="visible"/></clipPath><path clip-path="url(#SVGID_2_)" fill="#ed2939" d="M10 10h80v40H10z"/><path clip-path="url(#SVGID_2_)" fill="#f7f7f7" d="M10 50h80v40H10z"/><path d="M39.311 30c0-7.632 6.032-13.856 13.589-14.163A14.982 14.982 0 0 0 47.948 15c-8.284 0-15 6.716-15 15s6.716 15 15 15c1.735 0 3.401-.295 4.951-.837-7.556-.307-13.588-6.531-13.588-14.163z" clip-path="url(#SVGID_2_)" fill="#fff"/><path id="star_14_" d="M49.805 30.362l-2.038-1.563-2.037 1.564.784-2.521-2.044-1.554 2.522.005.774-2.524.775 2.524 2.522-.006-2.044 1.555.786 2.52z" clip-path="url(#SVGID_2_)" fill="#fff"/><path id="star_13_" d="M65.793 30.362l-2.038-1.563-2.037 1.564.784-2.521-2.044-1.554 2.522.005.774-2.524.775 2.524 2.522-.006-2.044 1.555.786 2.52z" clip-path="url(#SVGID_2_)" fill="#fff"/><path id="star_12_" d="M57.807 24.428l-2.038-1.563-2.037 1.564.784-2.521-2.044-1.554 2.522.005.774-2.524.775 2.524 2.522-.006-2.044 1.555.786 2.52z" clip-path="url(#SVGID_2_)" fill="#fff"/><path id="star_11_" d="M52.796 39.856l-2.038-1.563-2.037 1.564.784-2.521-2.044-1.554 2.522.005.774-2.524.775 2.524 2.522-.006-2.044 1.555.786 2.52z" clip-path="url(#SVGID_2_)" fill="#fff"/><path id="star_10_" d="M62.802 39.856l-2.038-1.563-2.037 1.564.784-2.521-2.044-1.554 2.522.005.774-2.524.775 2.524 2.522-.006-2.044 1.555.786 2.52z" clip-path="url(#SVGID_2_)" fill="#fff"/></g><metadata xmlns:d="https://loading.io/stock/"><d:name>singapore</d:name><d:tags>singapore,national flag,country,nation symbol</d:tags><d:license>by</d:license><d:slug>vu7y7z</d:slug></metadata></svg>
        `}} />
    );
  }
}

export default Logo;
