export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const CHANGE_ACTIVE_SIDEBAR_ITEM = 'CHANGE_ACTIVE_SIDEBAR_ITEM';
export const CHANGE_ACTIVE_SIDEBAR_HEADER = 'CHANGE_ACTIVE_SIDEBAR_HEADER';
export const SET_IS_SCROLLING = 'SET_IS_SCROLLING';

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR,
  };
}

export function changeActiveSidebarItem(activeItem) {
  return {
    type: CHANGE_ACTIVE_SIDEBAR_ITEM,
    activeItem,
  };
}

export function changeActiveSidebarHeader(activeHeader) {
    return {
      type: CHANGE_ACTIVE_SIDEBAR_HEADER,
      activeHeader,
    };
}

export function setIsScrolling(isScrolling) {
    return {
      type: SET_IS_SCROLLING,
      isScrolling,
    };
}
