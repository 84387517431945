import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import SgLionLogo from "../Icons/SgLionLogo.js";
import cn from "classnames";
import Data from "../../data/Data.js"

import * as Icons from "@material-ui/icons";
import "font-awesome/css/font-awesome.min.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "eva-icons/style/eva-icons.css";

const Sidebar = (props) => {
//   const {
//     activeItem = '',
//     activeHeader = '',
//     ...restProps
//   } = props;

  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true)
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false)
      }, 0);
    }
  }, [props.sidebarOpened])

  return (
    <nav className={cn(s.root, {[s.sidebarOpen]: burgerSidebarOpen})} >
      <header className={s.logo}>
        <SgLionLogo/>
        <span className={s.title}>SG Referrals</span>
      </header>
      <h5 className={s.navTitle}>Categories</h5>
      <ul className={s.nav}>
        {Data.map(sectionData =>(
            <LinksGroup
            isActive={props.activeHeader === `/${sectionData.id}`}
            onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={props.activeItem}
            header={sectionData.title}
            isHeader
            iconName={<i className={sectionData.icon}/>}
            link={`/${sectionData.id}`}
            index={sectionData.id}
            elementId={sectionData.id}
            childrenLinks={sectionData.items.map(cardData => ({
                header: cardData.title,
                link: `/${sectionData.id}/${cardData.id}`,
                elementId: cardData.id,
                isActive: props.activeItem === `/${sectionData.id}/${cardData.id}`, 
            }))}    
            />
        ))}
      </ul>
      {/* <div className="bg-widget d-flex mt-auto ml-1">
        <Button className="rounded-pill my-3 body-2 d-none d-md-block" type="submit" color="secondary-red">Unlock Full Version</Button>
      </div> */}
    </nav>
  );
}

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  activeHeader: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
    activeHeader: store.navigation.activeHeader,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
