import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import IbkrLogo from "../../components/Icons/IbkrLogo.js";
import TdaLogo from "../../components/Icons/TdaLogo.js";
import TigerLogo from "../../components/Icons/TigerLogo.js";
import MoomooLogo from "../../components/Icons/MoomooLogo";
import Card from "./Card.js";
import Data from "../../data/Data.js"
import { connect } from "react-redux";
import { setIsScrolling } from "../../actions/navigation";
import SectionHeader from "./SectionHeader";

const Cards = (props) => {

    // useEffect(() => {
    //     let scrollTimeout;
    //     let scrollCount = 0;
    //     window.addEventListener('scroll', (e) => {
    //         if (scrollCount === 0) props.dispatch(setIsScrolling(true))
    //         clearTimeout(scrollTimeout);
    //         scrollTimeout = setTimeout(function() {
    //             console.log('Scroll ended');
    //             scrollCount = 0;
    //             props.dispatch(setIsScrolling(false))
    //         }, 100);
    //         scrollCount = scrollCount + 1;
    //     })
    // }, [])

  return (
    <div>
        {
            Data.map(sectionData => (
                <>
                <SectionHeader sectionData={sectionData}/>
                 {/* <h2 key={sectionData.id} id={sectionData.id} className="mb-4">{sectionData.title}</h2> */}
                 {
                     sectionData.items.map(cardData => (
                        <Card
                            sectionData={sectionData}
                            cardData={cardData}
                        />
                     ))
                 }
                </>
            ))
        }
    </div>
  )
}

Cards.propTypes = {
    dispatch: PropTypes.func.isRequired,
  }
  
  function mapStateToProps(store) {
    return {};
  }

export default connect(mapStateToProps)(Cards);
