import React from 'react';

class IbkrLogo extends React.Component {
  render() {
    return (
        <span dangerouslySetInnerHTML={{__html: `
        <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" viewBox="0 0 119.90376 18.22439" version="1.1" id="svg9835" inkscape:version="0.92.0 r15299" sodipodi:docname="interactive brokers.svg"><link xmlns="" type="text/css" id="dark-mode" rel="stylesheet" href=""/>
        <defs id="defs9829">
            <linearGradient gradientTransform="matrix(-1,0,0,1,3894.2446,0)" y2="49.000801" x2="3893.3887" y1="49.000801" x1="3864.9817" gradientUnits="userSpaceOnUse" id="SVGID_1_">
            <stop id="stop9697" style="stop-color:#D81222" offset="0"/>
            <stop id="stop9699" style="stop-color:#960B1A" offset="1"/>
            </linearGradient>
        </defs>
        <sodipodi:namedview id="base" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:zoom="1.9959945" inkscape:cx="224.70002" inkscape:cy="32.550025" inkscape:document-units="mm" inkscape:current-layer="layer1" showgrid="false" fit-margin-top="0" fit-margin-left="0" fit-margin-right="0" fit-margin-bottom="0" inkscape:window-width="1280" inkscape:window-height="744" inkscape:window-x="-4" inkscape:window-y="-4" inkscape:window-maximized="1"/>
        <metadata id="metadata9832">
            <rdf:RDF>
            <cc:Work rdf:about="">
                <dc:format>image/svg+xml</dc:format>
                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                <dc:title/>
            </cc:Work>
            </rdf:RDF>
        </metadata>
        <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(12.582832,-170.97114)">
            <g id="g9910" transform="translate(0.50000041,-0.50000076)">
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="M 1.9428016,188.93094 H 0.03780162 v -9.2075 H 1.9428016 Z" id="path9659"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 9.1130116,182.50157 c 0.44979,0.37041 0.66146,0.97896 0.66146,1.82562 v 4.60375 h -1.82563 v -4.1275 c 0,-0.37041 -0.0529,-0.635 -0.13229,-0.82021 -0.18521,-0.34395 -0.50271,-0.52916 -1.00542,-0.52916 -0.60854,0 -1.00541,0.26458 -1.24354,0.76729 -0.10583,0.26458 -0.18521,0.60854 -0.18521,1.03187 v 3.70417 h -1.77271 v -6.82625 h 1.7198 v 1.00542 c 0.23812,-0.34396 0.44979,-0.60854 0.635,-0.76729 0.37041,-0.26459 0.8202,-0.42334 1.40229,-0.42334 0.71437,0 1.29646,0.18521 1.74625,0.55563 z" id="path9661"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 10.647592,183.45407 v -1.27 h 0.9525 v -1.905 h 1.77271 v 1.905 h 1.111249 v 1.27 h -1.111249 v 3.59833 c 0,0.29104 0.0265,0.44979 0.10583,0.52917 0.0794,0.0794 0.29104,0.10583 0.661461,0.10583 0.0529,0 0.10583,0 0.185208,0 0.0529,0 0.132292,0 0.185211,0 v 1.32292 l -0.89959,0.0265 c -0.84666,0.0265 -1.42875,-0.10584 -1.71979,-0.4498 -0.21166,-0.21166 -0.29104,-0.52916 -0.29104,-0.9525 v -4.18041 z" id="path9663"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 19.960923,182.26344 c 0.47625,0.21167 0.873128,0.55563 1.190628,1.00542 0.291042,0.39687 0.449792,0.87312 0.555622,1.40229 0.0529,0.3175 0.0794,0.76729 0.0529,1.34938 h -4.974169 c 0.0265,0.68791 0.264591,1.16416 0.71438,1.42875 0.264581,0.15875 0.608539,0.26458 0.978961,0.26458 0.39687,0 0.740828,-0.10583 0.978959,-0.3175 0.132291,-0.10583 0.26458,-0.26458 0.370411,-0.47625 h 1.82563 c -0.0529,0.39687 -0.264591,0.82021 -0.661461,1.24354 -0.608539,0.66146 -1.455211,1.00542 -2.566461,1.00542 -0.899578,0 -1.719789,-0.29104 -2.407708,-0.84667 -0.687911,-0.55562 -1.03187,-1.48167 -1.03187,-2.72521 0,-1.19062 0.3175,-2.09021 0.9525,-2.72521 0.635,-0.635 1.42875,-0.9525 2.434159,-0.9525 0.582091,0.0265 1.11125,0.1323 1.5875,0.34396 z m -2.672291,1.53459 c -0.264581,0.26458 -0.423331,0.60854 -0.47625,1.05833 h 3.069169 c -0.0265,-0.47625 -0.185208,-0.82021 -0.47625,-1.08479 -0.291039,-0.23813 -0.635,-0.37042 -1.058328,-0.37042 -0.4498,0 -0.79375,0.13229 -1.058341,0.39688 z" id="path9665"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 26.575512,181.9724 c 0.0265,0 0.0794,0 0.15875,0 v 1.82563 c -0.105839,0 -0.211669,-0.0265 -0.291039,-0.0265 -0.0794,0 -0.15875,0 -0.211672,0 -0.714378,0 -1.190628,0.23812 -1.455208,0.68791 -0.132292,0.26459 -0.21167,0.66146 -0.21167,1.21709 v 3.25437 h -1.799161 v -6.79979 h 1.693331 v 1.19063 c 0.26458,-0.4498 0.502708,-0.7673 0.71438,-0.92605 0.34395,-0.29104 0.767289,-0.42333 1.29645,-0.42333 0.0529,-0.0265 0.0794,-0.0265 0.105839,0 z" id="path9667"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 30.464882,184.80344 c 0.343961,-0.0529 0.582091,-0.10583 0.71438,-0.15875 0.264581,-0.10583 0.39687,-0.26458 0.39687,-0.50271 0,-0.29104 -0.105828,-0.47625 -0.291039,-0.58208 -0.185211,-0.10583 -0.47625,-0.15875 -0.87312,-0.15875 -0.423341,0 -0.740841,0.10583 -0.899591,0.3175 -0.132289,0.15875 -0.211659,0.37042 -0.264581,0.635 h -1.719789 c 0.0265,-0.60854 0.211661,-1.08479 0.502711,-1.48167 0.47625,-0.60854 1.29645,-0.89958 2.434159,-0.89958 0.740841,0 1.42875,0.15875 2.010841,0.44979 0.582081,0.29104 0.87312,0.84667 0.87312,1.66688 v 3.12208 c 0,0.21167 0,0.47625 0,0.79375 0,0.23813 0.0529,0.39688 0.10583,0.47625 0.0529,0.0794 0.15875,0.15875 0.264589,0.21167 v 0.26458 h -1.931458 c -0.0529,-0.13229 -0.0794,-0.26458 -0.105831,-0.39687 -0.0265,-0.1323 -0.0265,-0.26459 -0.0529,-0.42334 -0.23813,0.26459 -0.529169,0.50271 -0.846669,0.68792 -0.39687,0.21167 -0.820211,0.34396 -1.322912,0.34396 -0.608549,0 -1.137708,-0.18521 -1.534588,-0.52917 -0.39687,-0.34396 -0.608542,-0.84667 -0.608542,-1.50812 0,-0.84667 0.3175,-1.45521 0.978961,-1.82563 0.370419,-0.21167 0.87312,-0.34396 1.5875,-0.44979 z m 1.084791,0.82021 c -0.10583,0.0794 -0.238122,0.13229 -0.34395,0.18521 -0.105841,0.0529 -0.264591,0.0794 -0.47625,0.13229 l -0.39688,0.0794 c -0.37042,0.0794 -0.661461,0.15875 -0.820211,0.23812 -0.291039,0.15875 -0.423331,0.42333 -0.423331,0.76729 0,0.3175 0.0794,0.52917 0.264581,0.66146 0.15875,0.13229 0.370419,0.21167 0.635,0.21167 0.39688,0 0.740841,-0.10584 1.084791,-0.34396 0.3175,-0.23813 0.502709,-0.635 0.502709,-1.24354 v -0.68792 z" id="path9669"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 38.931554,184.56532 c -0.0265,-0.26459 -0.105831,-0.47625 -0.264581,-0.68792 -0.211669,-0.26458 -0.502711,-0.42333 -0.92605,-0.42333 -0.608541,0 -1.005411,0.29104 -1.243541,0.89958 -0.105828,0.3175 -0.185209,0.74083 -0.185209,1.27 0,0.50271 0.0529,0.89958 0.185209,1.21708 0.211672,0.55563 0.608541,0.84667 1.217091,0.84667 0.423331,0 0.71437,-0.10583 0.899581,-0.34396 0.185208,-0.23812 0.291039,-0.52916 0.3175,-0.87312 h 1.825619 c -0.0529,0.55562 -0.238119,1.05833 -0.58208,1.53458 -0.55562,0.76729 -1.375831,1.16417 -2.487081,1.16417 -1.11125,0 -1.905,-0.3175 -2.434169,-0.97896 -0.52917,-0.66146 -0.767289,-1.50813 -0.767289,-2.54 0,-1.16417 0.291039,-2.09021 0.846669,-2.72521 0.582081,-0.66146 1.34937,-0.97896 2.38125,-0.97896 0.846659,0 1.561039,0.18521 2.0902,0.58209 0.52917,0.39687 0.873131,1.05833 0.978959,2.03729 z" id="path9671"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 41.339262,183.45407 v -1.27 h 0.9525 v -1.905 h 1.772711 v 1.905 h 1.11125 v 1.27 h -1.11125 v 3.59833 c 0,0.29104 0.0264,0.44979 0.105831,0.52917 0.0794,0.0794 0.291039,0.10583 0.661458,0.10583 0.0529,0 0.105831,0 0.185211,0 0.0529,0 0.132289,0 0.1852,0 v 1.32292 l -0.873119,0.0265 c -0.846672,0.0265 -1.42875,-0.10584 -1.719792,-0.4498 -0.211669,-0.21166 -0.291039,-0.52916 -0.291039,-0.9525 v -4.18041 z" id="path9673"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 48.165512,181.31094 h -1.799169 v -1.64041 h 1.799169 z m -1.82563,0.82021 h 1.799172 v 6.82625 h -1.799172 z" id="path9675"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 53.959882,182.13115 h 1.905 l -2.46062,6.82625 h -1.878539 l -2.434169,-6.82625 h 2.010828 l 1.42875,5.02708 z" id="path9677"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 61.077173,182.26344 c 0.47625,0.21167 0.873131,0.55563 1.190631,1.00542 0.291039,0.39687 0.449789,0.87312 0.555619,1.40229 0.0529,0.3175 0.0794,0.76729 0.0529,1.34938 h -4.974169 c 0.0265,0.68791 0.264591,1.16416 0.71438,1.42875 0.264581,0.15875 0.608539,0.26458 0.978961,0.26458 0.39687,0 0.740828,-0.10583 0.978959,-0.3175 0.132291,-0.10583 0.26458,-0.26458 0.370411,-0.47625 h 1.82563 c -0.0529,0.39687 -0.264591,0.82021 -0.661461,1.24354 -0.608539,0.66146 -1.455211,1.00542 -2.566461,1.00542 -0.899578,0 -1.719789,-0.29104 -2.407708,-0.84667 -0.687911,-0.55562 -1.03187,-1.48167 -1.03187,-2.72521 0,-1.19062 0.3175,-2.09021 0.9525,-2.72521 0.635,-0.635 1.42875,-0.9525 2.434159,-0.9525 0.582091,0.0265 1.11125,0.1323 1.5875,0.34396 z m -2.672291,1.53459 c -0.264578,0.26458 -0.423328,0.60854 -0.47625,1.05833 h 3.069172 c -0.0265,-0.47625 -0.185211,-0.82021 -0.47625,-1.08479 -0.291042,-0.23813 -0.635,-0.37042 -1.058331,-0.37042 -0.4498,0 -0.79375,0.13229 -1.058341,0.39688 z" id="path9679"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 64.040512,179.7499 h 3.96875 c 1.084792,0 1.852081,0.3175 2.30187,0.9525 0.264591,0.37042 0.39688,0.82021 0.39688,1.32292 0,0.58208 -0.15875,1.05833 -0.502708,1.42875 -0.15875,0.18521 -0.423331,0.37041 -0.740831,0.52916 0.47625,0.18521 0.8202,0.37042 1.058331,0.60855 0.423328,0.39687 0.608539,0.9525 0.608539,1.66687 0,0.58208 -0.185211,1.13771 -0.55562,1.61396 -0.55563,0.71437 -1.455211,1.08479 -2.6723,1.08479 h -3.91583 v -9.2075 z m 3.518961,3.88938 c 0.529159,0 0.9525,-0.0794 1.243539,-0.21167 0.47625,-0.23813 0.687911,-0.635 0.687911,-1.24354 0,-0.60854 -0.238119,-1.00542 -0.74083,-1.21709 -0.291039,-0.10583 -0.68792,-0.1852 -1.243539,-0.1852 h -2.248961 v 2.8575 z m 0.423331,4.23333 c 0.767289,0 1.322919,-0.21167 1.640419,-0.66146 0.211659,-0.29104 0.3175,-0.60854 0.3175,-1.00542 0,-0.66145 -0.29105,-1.11125 -0.899591,-1.37583 -0.3175,-0.13229 -0.740828,-0.18521 -1.27,-0.18521 h -2.487078 v 3.22792 z" id="path9681"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 72.507173,182.23698 h 1.058339 v 1.16417 c 0.0794,-0.23812 0.291042,-0.50271 0.635,-0.82021 0.343961,-0.3175 0.740831,-0.47625 1.19062,-0.47625 0.0265,0 0.0529,0 0.105841,0 0.0529,0 0.132289,0 0.264581,0.0265 v 1.19063 c -0.0794,0 -0.132292,-0.0265 -0.185211,-0.0265 -0.0529,0 -0.105831,0 -0.185211,0 -0.55562,0 -1.005409,0.18521 -1.296459,0.55562 -0.3175,0.37042 -0.449791,0.79375 -0.449791,1.27 v 3.86292 h -1.137709 z" id="path9683"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 81.529473,182.89844 c 0.582081,0.58209 0.899581,1.42875 0.899581,2.54 0,1.08479 -0.264581,1.98438 -0.79375,2.67229 -0.529172,0.71438 -1.322922,1.05834 -2.434172,1.05834 -0.926039,0 -1.640409,-0.3175 -2.169578,-0.92604 -0.529172,-0.635 -0.820211,-1.45521 -0.820211,-2.51355 0,-1.1377 0.291039,-2.01083 0.846669,-2.69875 0.55562,-0.66145 1.322911,-1.00541 2.275411,-1.00541 0.873131,0.0265 1.5875,0.3175 2.19605,0.87312 z m -0.71438,4.47146 c 0.26458,-0.55562 0.42333,-1.19062 0.42333,-1.87854 0,-0.635 -0.10583,-1.13771 -0.291041,-1.53458 -0.3175,-0.60855 -0.846659,-0.92605 -1.613959,-0.92605 -0.687911,0 -1.164161,0.26459 -1.481661,0.79375 -0.3175,0.52917 -0.47625,1.16417 -0.47625,1.905 0,0.71438 0.15875,1.29646 0.47625,1.77271 0.3175,0.47625 0.79375,0.71438 1.481661,0.71438 0.714381,0 1.217089,-0.29104 1.48167,-0.84667 z" id="path9685"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 83.672593,179.7499 h 1.084789 v 5.34458 l 2.883956,-2.8575 h 1.42875 l -2.566456,2.48709 2.725206,4.20687 h -1.42875 l -2.116665,-3.41312 -0.926041,0.89958 v 2.51354 h -1.084789 z" id="path9687"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 93.911968,182.42219 c 0.44979,0.21167 0.79375,0.50271 1.00542,0.87313 0.21167,0.34396 0.37042,0.74083 0.44979,1.19062 0.0794,0.3175 0.10583,0.79375 0.10583,1.48167 h -4.86833 c 0.0265,0.68792 0.1852,1.21708 0.47625,1.64042 0.29104,0.42333 0.76729,0.60854 1.37583,0.60854 0.58208,0 1.05833,-0.18521 1.4023,-0.58209 0.1852,-0.23812 0.34395,-0.47625 0.42332,-0.79375 h 1.11126 c -0.0265,0.23813 -0.1323,0.52917 -0.29105,0.82021 -0.15875,0.29104 -0.34396,0.55563 -0.55562,0.74084 -0.34396,0.34395 -0.76729,0.55562 -1.27,0.68791 -0.26459,0.0794 -0.58209,0.10584 -0.92604,0.10584 -0.82021,0 -1.53459,-0.29105 -2.11667,-0.89959 -0.58208,-0.60854 -0.87313,-1.45521 -0.87313,-2.54 0,-1.08479 0.29105,-1.95791 0.87313,-2.61937 0.58208,-0.66146 1.34937,-1.00542 2.27542,-1.00542 0.5027,-0.0529 0.9525,0.0794 1.40229,0.29104 z m 0.42334,2.64584 c -0.0529,-0.47625 -0.15875,-0.87313 -0.3175,-1.16417 -0.3175,-0.55563 -0.82022,-0.82021 -1.53459,-0.82021 -0.50271,0 -0.9525,0.18521 -1.29646,0.55563 -0.34395,0.37041 -0.52917,0.84666 -0.55563,1.42875 z" id="path9689"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 96.822388,182.23698 h 1.05833 v 1.16417 c 0.0794,-0.23812 0.29104,-0.50271 0.635,-0.82021 0.34396,-0.3175 0.74084,-0.47625 1.19062,-0.47625 0.0265,0 0.0529,0 0.10584,0 0.0529,0 0.13229,0 0.264582,0.0265 v 1.19063 c -0.0794,0 -0.132292,-0.0265 -0.185202,-0.0265 -0.0529,0 -0.10584,0 -0.18522,0 -0.55562,0 -1.00541,0.18521 -1.29645,0.55562 -0.3175,0.37042 -0.4498,0.79375 -0.4498,1.27 v 3.86292 h -1.1377 z" id="path9691"/>
            <path style="fill:#000000;stroke-width:0.26458332" inkscape:connector-curvature="0" class="st0" d="m 101.74364,186.84073 c 0.0265,0.37042 0.13229,0.66146 0.29104,0.87313 0.29104,0.37042 0.79375,0.55562 1.50813,0.55562 0.42333,0 0.79375,-0.0794 1.1377,-0.26458 0.3175,-0.18521 0.47625,-0.44979 0.47625,-0.84667 0,-0.29104 -0.13229,-0.5027 -0.39687,-0.66145 -0.15875,-0.0794 -0.50271,-0.18521 -0.97896,-0.3175 l -0.89959,-0.23813 c -0.58208,-0.13229 -1.00541,-0.29104 -1.29645,-0.47625 -0.50271,-0.3175 -0.74083,-0.71437 -0.74083,-1.27 0,-0.635 0.23812,-1.13771 0.68791,-1.53458 0.44979,-0.39688 1.08479,-0.58209 1.87854,-0.58209 1.03188,0 1.77271,0.29105 2.2225,0.89959 0.29105,0.37041 0.42333,0.79375 0.42333,1.21708 h -1.05833 c -0.0265,-0.26458 -0.10583,-0.47625 -0.26458,-0.68792 -0.26459,-0.29104 -0.71437,-0.44979 -1.37584,-0.44979 -0.42333,0 -0.76728,0.0794 -0.97895,0.23813 -0.21167,0.15875 -0.34396,0.37041 -0.34396,0.635 0,0.29104 0.15875,0.52916 0.44979,0.68791 0.15875,0.10584 0.42334,0.18521 0.74084,0.26459 l 0.76728,0.18521 c 0.82022,0.1852 1.37584,0.39687 1.66688,0.58208 0.44979,0.29104 0.66146,0.74083 0.66146,1.34937 0,0.60855 -0.23812,1.11125 -0.68792,1.53459 -0.44979,0.42333 -1.16417,0.66146 -2.0902,0.66146 -1.00542,0 -1.7198,-0.23813 -2.14313,-0.68792 -0.42334,-0.44979 -0.635,-1.00542 -0.68792,-1.69333 h 1.03188 z" id="path9693"/>
            <polygon transform="matrix(0.26458333,0,0,0.26458333,-12.820957,171.83885)" style="fill:url(#SVGID_1_)" class="st1" points="0.9,64.6 0.9,33.4 29.3,64.6 " id="polygon9702"/>
            <circle style="fill:#d81222;stroke-width:0.26458332" class="st2" cx="-6.1269836" cy="182.68678" r="2.2489583" id="circle9704"/>
            <polygon transform="matrix(0.26458333,0,0,0.26458333,-12.820957,171.83885)" style="fill:#d81222" class="st2" points="0.9,33.4 0.9,64.6 29.3,0.5 " id="polygon9706"/>
            </g>
        </g>
        </svg>
        `}} />
    );
  }
}

export default IbkrLogo;
