import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { useLocation } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
    Button,
  Col,
  Row,
} from "reactstrap";
import s from "./Card.module.scss";
import Widget from "../../components/Widget/Widget";
import { changeActiveSidebarItem, changeActiveSidebarHeader } from "../../actions/navigation.js";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Card = (props) => {
    const cardRef = useRef(null);
    const sectionData = props.sectionData;
    const cardData = props.cardData;
    const parentLink = `/${sectionData.id}`;
    const link=`/${sectionData.id}/${cardData.id}`;
    const Logo = cardData.logo;
    const location = useLocation()

    useEffect(() => {
        const observerMargin = Math.floor(window.innerHeight / 2);
        // const observerMargin = Math.floor(window.innerHeight / 3);
        const observerConfig = {
            rootMargin: `-${window.innerHeight % 2 === 0 ? observerMargin - 1 : observerMargin}px 0px -${observerMargin}px 0px`,
          };
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.target.id !== props.activeItem && entry.isIntersecting) {
                    props.dispatch(changeActiveSidebarHeader(parentLink))
                    props.dispatch(changeActiveSidebarItem(link));
                }
            });
        };
        const observer = new IntersectionObserver(handleIntersection, observerConfig);
        if (cardRef.current) observer.observe(cardRef.current);

        if (cardRef.current && link === location.pathname) {
            cardRef.current.scrollIntoView({
                behavior: 'smooth',
                // block: 'center',
                // inline: 'center'
            })
            props.dispatch(changeActiveSidebarHeader(parentLink))
            props.dispatch(changeActiveSidebarItem(link));
        }

        return () => {
            if (cardRef.current) observer.disconnect()
        }
    }, [cardRef])

    return (
        <div ref={cardRef} id={cardData.id}>
        <Row className="gutter" >
            <Col className="mb-4" xs={12}>
            <Widget className="widget-p-lg">
                <Row>
                <Col xs={12} sm={6} lg={3} className="mt-5 mt-sm-0 mb-5 mb-sm-0">
                    <div className={s.iconDivOuter}>
                        <div className={s.iconDivMiddle}>
                            <div className={s.iconDivInner}>
                                <Logo />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <div className="mt-5 mt-sm-0 pt-3 pt-sm-0 mb-4">
                    <p className="headline-1 mb-0">{cardData.title}</p>
                    &nbsp;
                    <p className="body-3 text-muted mb-0">{cardData.description}</p>
                    {/* <p className="body-3 text-muted mb-0">29 px, Semi-bold</p>
                    </div>
                    <div className="mb-4">
                    <p className="headline-2 mb-0">Headline 2</p>
                    <p className="body-3 text-muted mb-0">20 px, Semi-bold</p> */}
                    </div>
                </Col>
                {cardData.email ? 
                <Col xs={12} lg={6} className="mt-0 mt-xl-0 mb-4 mb-sm-0">
                    <Row>
                        <Col xs={12}>
                            <p className="headline-2 mb-3">Referral:</p>
                        </Col>
                        <Col xs={12}>
                            <h2 className={s.referralCode}>{cardData.code}</h2>
                        </Col>
                    </Row>
                </Col> 
                : 
                <Col xs={12} lg={6} className="mt-0 mt-xl-0 mb-4 mb-sm-0">
                    &nbsp;
                    &nbsp;
                    <Row>
                        <Col xs={12} sm={12} lg={12} xl={3}>
                            <p className="headline-2 mb-3">Referral Code:</p>
                        </Col>
                        <Col xs={12} sm={8} lg={8} xl={6}>
                            <h2 className={s.referralCode}>{cardData.code}</h2>
                        </Col>
                        <Col xs={12} sm={4} lg={4} xl={3}>
                            <button
                            onClick={() => {
                                // Copy to clipboard
                                navigator.clipboard.writeText(cardData.code)

                                // Send out notification
                                const options = {
                                    autoClose: 4000,
                                    closeButton: false,
                                    hideProgressBar: true,
                                    position: toast.POSITION.TOP_CENTER,
                                  };
                                toast(
                                    <Notification
                                        type={"success"}
                                        withIcon
                                        message={"Copied to Clipboard"}
                                    />,
                                    options
                                    )
                            }}
                            className={s.copyButton}
                            ><ContentCopyIcon/> Copy</button>
                        </Col>
                    </Row>
                    &nbsp;
                    <Row>
                        <Col xs={12} md={3} lg={12} xl={3}>
                            <p className="headline-2 mb-3">Referral Link:</p>
                        </Col>
                        <Col xs={12} md={9} lg={12} xl={9}>
                        {/* <Col xs={12} sm={8} lg={8} xl={6}> */}
                            <div className={s.referralLink}>
                            <a target="_blank" rel="noreferrer" href={cardData.link} className="body-3 mb-0">{cardData.link}</a>
                            </div>
                        </Col>
                        {/* <Col xs={12} sm={4} lg={4} xl={3}>
                        <button
                            onClick={() => {
                                // Copy to clipboard
                                navigator.clipboard.writeText(cardData.code)

                                // Send out notification
                                const options = {
                                    autoClose: 4000,
                                    closeButton: false,
                                    hideProgressBar: true,
                                    position: toast.POSITION.TOP_CENTER,
                                  };
                                toast(
                                    <Notification
                                        type={"success"}
                                        withIcon
                                        message={"Copied to Clipboard"}
                                    />,
                                    options
                                    )
                            }}
                            className={s.copyButton}
                            ><ContentCopyIcon/> Share</button>
                        </Col> */}
                    </Row>
                    {/* <p className="headline-3 mb-4 text-muted">Fonts</p> */}
                    {/* <p className="body-3 text-muted">The goal of this new type was to create a sans serif font which give an impression of both modernism, harmony and roundness. These nuances give Sofia a harmonious and sensible appearance for both texts and headlines. </p>
                    <p className="body-3 text-muted">This typeface is a complete redesign of Sofia initially designed in 2008. this typeface supports a wide range of languages with more than 500 glyphs. </p> */}
                </Col>
                }
                </Row>
            </Widget>
            </Col>
        </Row>
        </div>
    )
}

Card.propTypes = {
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string.isRequired,
    isScrolling: PropTypes.bool.isRequired,
    sectionData: PropTypes.any.isRequired,
    cardData: PropTypes.any.isRequired,
}

function mapStateToProps(store) {
    return {
        activeItem: store.navigation.activeItem,
        isScrolling: store.navigation.isScrolling,
    };
}

export default connect(mapStateToProps)(Card);
