import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  CHANGE_ACTIVE_SIDEBAR_ITEM,
  CHANGE_ACTIVE_SIDEBAR_HEADER,
  SET_IS_SCROLLING,
} from "../actions/navigation.js";

const initialState = {
  sidebarOpened: false,
//   activeItem: JSON.parse(localStorage.getItem('staticSidebar')) ? window.location.pathname : null,
  activeItem: null,
  activeHeader: null,
  isScrolling: false,
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: true,
      });
    case CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: false,
      });
    case CHANGE_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.activeItem,
      };
    case CHANGE_ACTIVE_SIDEBAR_HEADER:
    return {
        ...state,
        activeHeader: action.activeHeader,
    };
    case SET_IS_SCROLLING:
    return {
        ...state,
        isScrolling: action.isScrolling,
    };
    default:
      return state;
  }
}
