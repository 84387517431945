import React from 'react';

class HodlnautLogo extends React.Component {
  render() {
    return (
        <span dangerouslySetInnerHTML={{__html: `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1190.6 360.1" style="enable-background:new 0 0 1190.6 360.1;" xml:space="preserve"><link xmlns="" type="text/css" id="dark-mode" rel="stylesheet" href=""/>
        <style type="text/css">
            .st0{fill:#2699FB;}
        </style>
        <g>
            <path class="st0" d="M411,231c-2.4,7.2-6.1,13.6-11.2,19.4c-8.9,10-30.3,9.4-36.5-7.2c-2.1-5.5-2.7-11.2-2.9-17   c0-2.1,0.2-4.1,0.3-6.2c0.3-10.4,2.1-20.5,6.3-30.1c3.4-7.6,8.3-13.7,16.3-16.8c6.2-2.4,12.6-2.5,18.9-0.4c5.9,1.9,9.2,6.4,11.2,12   c1.9,5.5,2.6,11.2,2.4,16.9C415.6,211.6,414.1,221.5,411,231 M452,178.5c-4-18.4-14.7-30.7-33-35.9c-11.1-3.2-22.5-3.6-33.9-2.3   c-25.6,3-43.6,16.5-54.1,39.9c-4.3,9.5-6.4,19.7-7.7,30c-1.7,12.9-1.7,25.7,1,38.4c3.5,16.3,12.5,28.2,27.9,35   c8.1,3.6,16.7,4.7,25.8,5.2c3.8,0,8.2,0.1,12.5-0.3c13-1.2,24.7-5.7,34.9-14c9.5-7.7,15.6-17.7,20.1-28.8c4.4-10.6,6.7-21.6,7.8-33   C454.4,201.3,454.5,189.8,452,178.5"/>
            <path class="st0" d="M611.7,140.4c-5.5,0-10.2,4.2-10.7,9.7l-10.9,115.6c-1.3,11.9,7.9,22.2,19.8,22.4l38,0.5   c9.6,0.1,17.7-6.9,19-16.4l1.4-10.7c0.5-3.4-2.2-6.4-5.6-6.4l-35.3,0.2l9.4-106.6c0.4-4.5-3.1-8.3-7.6-8.3H611.7z"/>
            <path class="st0" d="M693.6,147.3l-10.8,133.1c-0.4,4.4,3.2,8.3,7.6,8.2l21-0.1c3.3,0,6.1-2.6,6.4-5.9l5.9-75.3l32.5,70.1   c3.2,7,10.2,11.4,17.8,11.4h15.8c4,0,7.3-3.1,7.6-7.1l9.2-133.1c0.3-4.4-3.2-8.1-7.6-8.1l-22.5,0c-3.3,0-6.1,2.5-6.4,5.8l-6.6,70.1   l-29.6-65c-3.3-7.2-10.5-11.7-18.4-11.5l-14.4,0.4C697.2,140.4,694,143.4,693.6,147.3z"/>
            <path class="st0" d="M481,138.8c-5.6,0.4-10,4.8-10.4,10.4l-8.2,115.6c-0.7,9.8,6.1,18.5,15.7,20.2c29.8,5.4,89.8,8.2,100.8-52.6   c0.7-3.8,1.1-7.6,1.3-11.5C582.3,183.5,592.2,130.7,481,138.8z M544.7,212.5c0,0.7-2.6,47.1-40.4,43.5c-3.2-0.3-5.6-3.2-5.4-6.4   l5.5-74.4c0.2-3,2.7-5.4,5.7-5.5C546.6,167.9,545.9,189.6,544.7,212.5z"/>
            <path class="st0" d="M937.2,278l-29.9-123.2c-2.1-8.5-9.7-14.5-18.5-14.4l-20,0.1c-3.6,0-6.7,2.2-7.9,5.6l-45.5,123.5   c-3.4,9.2,3.4,18.9,13.2,18.9h14.8c3.7,0,7-2.4,8.1-6l8.9-28.3l31.3,0l6.1,23.7c1.6,6.2,7.2,10.6,13.6,10.6H929   C934.5,288.6,938.5,283.4,937.2,278z M888.1,222.6l-20.8,0.3c-1,0-1.8-1-1.4-1.9l13.7-43.5c0.5-1.5,2.6-1.3,2.9,0.2l7.1,43.3   C889.7,221.8,889,222.6,888.1,222.6z"/>
            <path class="st0" d="M957.7,147.3l-6,82.1c-2.3,31.8,22.9,58.9,54.8,58.9h0c28.6,0,52.4-21.9,54.8-50.3l6.5-76.7   c1-11.5-8.1-21.3-19.6-21.3h-9.2c-4,0-7.4,3.1-7.8,7.1l-8.9,91.6c-0.8,8.6-8.1,15.2-16.8,15.2l0,0c-9.9,0-17.7-8.5-16.8-18.3   l6.5-74.2c1-11.5-8-21.3-19.6-21.3h-10.1C961.4,140,958,143.2,957.7,147.3z"/>
            <path class="st0" d="M1083.6,151.3l-2.2,13.3c-0.4,2.3,1.4,4.4,3.8,4.4h29.1l-7.3,95.4c-1.6,12.7,8.3,23.9,21.1,23.9h11   c1.9,0,3.5-1.4,3.8-3.3l10.3-116h21.2c6.8,0,12.6-4.9,13.7-11.6l2.3-13.4c0.4-2.3-1.4-4.5-3.8-4.5h-89.3   C1090.6,139.6,1084.7,144.5,1083.6,151.3z"/>
            <g>
                <g>
                    <path class="st0" d="M151.9,356.3L151.9,356.3c-7.4-11.7-9.8-26-6.4-39.4l20.3-82.4c0.6-2.5-1.3-4.8-3.9-4.7l-29.1,1.4     c-5.2,0.3-9.8,3.8-11.3,8.8l-21.8,70.6c-2.2,7.2-10.5,10.5-17.1,6.8l-7.3-4.1c-12.6-7-18.8-21.7-15.1-35.6l38.2-145.9     c2.4-9.1,10.8-15.3,20.2-14.8l23.9,1.1c5.1,0.2,8.8,5.1,7.6,10.1l-15.1,60.2c-0.5,2,1.2,3.9,3.3,3.6l29-1.8     c5.6-0.9,10.2-5,11.6-10.5l30.5-116.8c0.3-1.2-0.3-2.4-1.5-2.9c-17-6.2-35.3-9.5-54.5-9.4C69.2,51.6,0.6,120.2,0,204.4     c-0.6,84.3,66.2,153.1,149.7,155.7C151.7,360.1,152.9,357.9,151.9,356.3L151.9,356.3z"/>
                    <path class="st0" d="M309.3,205.5c0-47.3-21.3-89.7-54.7-118c-1-0.8-2.4-0.4-2.8,0.8c-14.7,49.1-65.6,226.2-63.9,261.9     c0.1,1.2-0.1,5.2,2.1,5.5c1.2,0.2,4.4-0.7,5.7-1.1C261.2,336.7,309.3,276.7,309.3,205.5z"/>
                </g>
                <g>
                    <path class="st0" d="M243.7,69.3c-2.4,10.8-8.4,13.1-8.4,13.1l0.5-6l-6.8,9.8c-0.1,0.2-0.4,0.1-0.4-0.1l0.7-11.9l-3.4,5     c0,0-3.3-5.6,1.5-15.5c0.1-0.2-0.2-0.4-0.3-0.3c-2.7,2.7-10.5,12-4.3,23.5c0.1,0.1,0.3,0.2,0.4,0l3.1-4.6L225,96.1     c0,0.2,0.3,0.3,0.4,0.1l7.6-11.6l-0.5,5.5c0,0.2,0.2,0.3,0.3,0.2c12-5.2,11.7-17.2,11.3-21.1C244.1,69.1,243.8,69.1,243.7,69.3z"/>
                    <path class="st0" d="M255.7,52.4C271.3,16,258.8,0,258.8,0l0,0c0,0-19.8,4.8-30.2,43l-8.7,2.9c-0.4,0.1-0.7,0.4-0.9,0.8     l-4.5,12.8c-0.4,1,0.5,2,1.6,1.8l14.6-3.3l0.4,2.7c0.2,0.9,0.8,1.7,1.7,2l7.2,2.5c0.9,0.3,1.9,0.1,2.5-0.5l2-1.8l9.4,11.6     c0.7,0.8,2,0.6,2.3-0.4l4.5-12.8c0.1-0.4,0.1-0.8-0.1-1.2L255.7,52.4z M244.8,40c-3.7-1.3-5.7-5.4-4.4-9.1     c1.3-3.7,5.4-5.7,9.1-4.4c3.7,1.3,5.7,5.4,4.4,9.1C252.6,39.3,248.6,41.3,244.8,40z"/>
                </g>
            </g>
        </g>
        </svg>
        `}} />
    );
  }
}

export default HodlnautLogo;
