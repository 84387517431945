import React from 'react';

class SgLionLogo extends React.Component {
  render() {
    return (
        <span dangerouslySetInnerHTML={{__html: `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29pt" height="35pt" viewBox="0 0 330.55 399" version="1.1"><link xmlns="" type="text/css" id="dark-mode" rel="stylesheet" href=""/>
        <defs>
        <clipPath id="clip1">
          <path d="M 149 26 L 330.550781 26 L 330.550781 247 L 149 247 Z M 149 26 "/>
        </clipPath>
        </defs>
        <g id="surface1">
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(85.899353%,0%,0%);fill-opacity:1;" d="M 8.59375 120 C 8.59375 120 -5.90625 140.5 13.09375 167 C 13.09375 167 16.09375 153.5 46.09375 153.5 L 82.09375 153.5 C 116.09375 153.5 141.59375 121 125.59375 87.5 C 125.59375 87.5 149.59375 90 157.59375 75 C 165.59375 60 157.09375 54 145.09375 54 L 84.59375 54 C 84.59375 64.75 64.09375 66.25 64.09375 54 L 30.09375 54 C 30.09375 54 4.59375 54 4.09375 75.5 C 4.09375 75.5 9.84375 72 15.59375 71.5 L 15.59375 77.25 C 15.59375 77.25 8.59375 78.5 5.34375 80.25 C 2.09375 82 -2.65625 86.75 1.84375 99 C 6.34375 111.25 8.09375 115.5 8.09375 115.5 C 8.09375 115.5 15.34375 109.25 27.09375 109.25 L 40.84375 109.25 C 65.34375 109.25 60.84375 133 36.34375 133 C 11.84375 133 8.59375 120 8.59375 120 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(85.899353%,0%,0%);fill-opacity:1;" d="M 136.34375 95.25 C 136.34375 95.25 144.59375 95.75 150.59375 90.5 C 150.59375 90.5 204.84375 132.75 124.34375 217.75 C 43.84375 302.75 106.09375 357.75 106.09375 357.75 C 106.09375 357.75 91.59375 371.75 99.84375 399 C 99.84375 399 66.363281 380.082031 40.761719 348 C 3.738281 301.605469 -18.875 230.605469 82.761719 174 C 82.761719 174 149.84375 140.25 136.34375 95.25 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(85.899353%,0%,0%);fill-opacity:1;" d="M 53.425781 46 C 53.425781 46 64.261719 26.667969 89.425781 26.667969 C 109.261719 26.667969 113.761719 16.5 113.761719 16.5 C 113.761719 16.5 122.425781 0 166.09375 0 C 206.113281 0 233.09375 13.335938 254.925781 31.167969 C 254.925781 31.167969 196.09375 -5.332031 138.09375 46 "/>
        <g clip-path="url(#clip1)" clip-rule="nonzero">
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(85.899353%,0%,0%);fill-opacity:1;" d="M 298.09375 168.335938 C 296.425781 108 249.761719 43.335938 149.425781 47 C 247.425781 -36 417.425781 149.335938 277.429688 246.664063 C 277.429688 246.664063 300.84375 213.273438 298.09375 168.335938 "/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(85.899353%,0%,0%);fill-opacity:1;" d="M 164.429688 53.667969 C 286.761719 50 330.429688 196.667969 251.429688 262.667969 L 172.09375 301 C 172.09375 301 161.761719 267.667969 200.09375 228.667969 C 238.425781 189.667969 275.425781 116.667969 169.425781 68.667969 C 169.425781 68.667969 171.429688 58.667969 164.429688 53.667969 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(85.899353%,0%,0%);fill-opacity:1;" d="M 158.09375 86.667969 C 158.09375 86.667969 164.09375 81 166.09375 76.335938 C 257.09375 114.335938 242.761719 178.667969 188.429688 232.667969 C 155.09375 267 165.429688 304 165.429688 304 C 165.429688 304 124.761719 329.332031 110.761719 351.332031 C 110.761719 351.332031 51.914063 301.210938 133.425781 218 C 213.429688 136.335938 158.09375 86.667969 158.09375 86.667969 "/>
        </g>
        </svg>
        `}} />
    );
  }
}

export default SgLionLogo;
