import React from 'react';

class GeminiLogo extends React.Component {
  render() {
    return (
        <span dangerouslySetInnerHTML={{__html: `
        <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" viewBox="0 0 210.92582 45.772921" version="1.1" id="svg1760" inkscape:version="0.92.0 r15299" sodipodi:docname="gemini.svg"><link xmlns="" type="text/css" id="dark-mode" rel="stylesheet" href=""/>
        <defs id="defs1754"/>
        <sodipodi:namedview id="base" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:zoom="1.0098212" inkscape:cx="397.09998" inkscape:cy="85.000014" inkscape:document-units="mm" inkscape:current-layer="layer1" showgrid="false" fit-margin-top="0" fit-margin-left="0" fit-margin-right="0" fit-margin-bottom="0" units="px" inkscape:window-width="1280" inkscape:window-height="744" inkscape:window-x="-4" inkscape:window-y="-4" inkscape:window-maximized="1"/>
        <metadata id="metadata1757">
          <rdf:RDF>
            <cc:Work rdf:about="">
              <dc:format>image/svg+xml</dc:format>
              <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
              <dc:title/>
            </cc:Work>
          </rdf:RDF>
        </metadata>
        <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(309.17318,-194.34167)">
          <g id="g1811" transform="translate(0.39688112,-0.39687465)">
            <g style="fill:#000000" transform="matrix(0.26458333,0,0,0.26458333,-309.17318,195.13542)" id="g1774">
          <path id="path1762" d="m 273.8,93.2 h 32.6 v 21.6 c -7,4.2 -16.5,6.8 -25.7,6.8 -23.2,0 -38.8,-15 -38.8,-37.4 0,-22.6 15.4,-37.8 38.2,-37.8 12.3,0 22.2,3.7 31.2,11.5 l 1.3,1.2 9.4,-15 -1,-0.8 c -11.9,-10 -25.4,-14.9 -41.1,-14.9 -15.9,0 -30.3,5.2 -40.6,14.7 -11,10.1 -16.8,24.3 -16.8,41 0,32.6 23.6,55.4 57.4,55.4 15.5,0 34.5,-6 45.1,-14.2 l 0.6,-0.5 V 76.1 h -51.7 v 17.1 z" style="fill:#000000" inkscape:connector-curvature="0"/>
      
          <polygon id="polygon1764" points="432.9,138 432.9,120.8 366.7,120.8 366.7,90.8 421.6,90.8 421.6,73.6 366.7,73.6 366.7,47.1 432.9,47.1 432.9,29.9 348.3,29.9 348.3,138 " style="fill:#000000"/>
      
          <polygon id="polygon1766" points="555.1,29.9 513,80.5 471,29.9 454.1,29.9 454.1,138 473.2,138 473.2,61.1 510.7,106.3 515.2,106.3 552.7,61.1 552.7,138 571.8,138 571.8,29.9 " style="fill:#000000"/>
      
          <rect id="rect1768" height="108.1" width="19.1" style="fill:#000000" y="29.9" x="599.90002"/>
      
          <polygon id="polygon1770" points="663.9,30.4 663.4,29.9 647,29.9 647,138 666.1,138 666.1,61.2 730.3,137.5 730.8,138 747.1,138 747.1,29.9 728,29.9 728,107 " style="fill:#000000"/>
      
          <rect id="rect1772" height="108.1" width="19.1" style="fill:#000000" y="29.9" x="775.09998"/>
      
      </g>
            <path id="path1776" d="m -279.77797,195.13542 c -7.91104,0 -14.63146,6.08542 -15.47813,13.91708 -7.83166,0.84667 -13.91708,7.56709 -13.91708,15.47813 0,8.59896 6.985,15.58396 15.58396,15.58396 7.91104,0 14.65791,-6.08542 15.47812,-13.91709 7.83167,-0.84666 13.91709,-7.56708 13.91709,-15.47812 0,-8.59896 -6.985,-15.58396 -15.58396,-15.58396 z m 11.93271,17.33021 c -0.7673,5.21229 -4.94771,9.39271 -10.16,10.16 v -10.16 z m -37.67667,10.31875 c 0.76729,-5.23875 4.94771,-9.41917 10.16,-10.18646 v 10.16 h -10.16 z m 23.86542,3.51896 c -0.87313,5.9002 -5.92667,10.29229 -11.93271,10.29229 -6.00604,0 -11.05959,-4.39209 -11.93271,-10.29229 z m 0.13229,-13.83771 v 10.29229 h -10.31875 v -10.29229 z m 13.67896,-3.51896 h -23.86542 c 0.87312,-5.90021 5.92667,-10.29229 11.93271,-10.29229 6.00604,0 11.05958,4.39208 11.93271,10.29229 z" style="fill:#00dcfa;stroke-width:0.26458332" inkscape:connector-curvature="0"/>
          </g>
        </g>
      </svg>
        `}} />
    );
  }
}

export default GeminiLogo;
