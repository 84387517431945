import React from 'react';

class BlockfiLogo extends React.Component {
  render() {
    return (
        <span dangerouslySetInnerHTML={{__html: `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" id="Layer_1" x="0px" y="0px" viewBox="0 0 160 35.5" enable-background="new 0 0 160 35.5" xml:space="preserve"><link xmlns="" type="text/css" id="dark-mode" rel="stylesheet" href=""/>
        <path d="M160,13.4h-4.1v15.8h4.1V13.4z"/>
        <path d="M160,5.8h-4.1v4.3h4.1V5.8z"/>
        <path d="M151.7,6v3.7h-10.4v6.5h8.9v3.7h-8.9v9.3h-4.2V6H151.7z"/>
        <path d="M125.7,19.6l7.5,9.6h-4.9l-5.3-6.8l-1.6,1.7v5.1h-4.1V5.9h4.1v13l6.1-6.5h4.9L125.7,19.6z"/>
        <path d="M112.4,24.6l1.2,3.4c-1.8,1.1-3.8,1.6-6,1.6c-2.7,0-4.8-0.8-6.3-2.4c-1.4-1.6-2.2-3.8-2.2-6.7c0-2.8,0.7-5,2.2-6.6  c1.4-1.6,3.5-2.4,6.3-2.4c1,0,1.8,0.1,2.5,0.2c0.7,0.1,1.4,0.3,2.2,0.6v3.8c-0.7-0.4-1.5-0.6-2.1-0.8c-0.7-0.2-1.4-0.2-2.2-0.2  c-3.1,0-4.6,1.8-4.6,5.3c0,3.6,1.5,5.4,4.5,5.4C109.4,26,110.9,25.5,112.4,24.6z"/>
        <path d="M94.9,16c0.6,1.4,1,2.9,1,4.6s-0.3,3.2-1,4.6c-0.6,1.4-1.7,2.4-2.9,3.2c-1.3,0.8-2.9,1.2-4.7,1.2c-1.9,0-3.5-0.4-4.8-1.2  c-1.3-0.8-2.3-1.9-3-3.2c-0.7-1.4-1-2.9-1-4.6c0-1.7,0.3-3.2,1-4.6c0.7-1.4,1.7-2.4,3-3.2c1.3-0.8,2.9-1.2,4.8-1.2  c1.9,0,3.5,0.4,4.7,1.2C93.2,13.6,94.2,14.6,94.9,16z M91.7,20.6c0-1.7-0.4-3-1.2-4s-1.9-1.5-3.3-1.5c-1.5,0-2.6,0.5-3.4,1.5  c-0.8,1-1.2,2.3-1.2,4s0.4,3,1.2,4c0.8,1,1.9,1.5,3.4,1.5c1.4,0,2.5-0.5,3.3-1.5C91.3,23.6,91.7,22.3,91.7,20.6z"/>
        <path d="M75.8,25.7v3.5c-0.9,0.2-1.8,0.3-2.6,0.3c-1.7,0-2.9-0.4-3.7-1.1c-0.8-0.7-1.2-1.8-1.2-3.2V5.9h4.1v18  c0,0.7,0.1,1.3,0.4,1.5c0.2,0.3,0.7,0.4,1.3,0.4C74.8,25.9,75.3,25.8,75.8,25.7z"/>
        <path d="M62.6,19.4c0.7,0.9,1.1,2,1.1,3.4c0,2.1-0.7,3.7-2,4.8c-1.3,1.1-3.5,1.6-6.7,1.6h-8.8V6H55c2.8,0,4.8,0.5,6,1.6  c1.2,1,1.8,2.6,1.8,4.7c0,1.3-0.2,2.3-0.8,3.2c-0.5,0.9-1.3,1.5-2.4,2C60.8,17.8,61.8,18.5,62.6,19.4z M58.4,24.9  c0.7-0.5,1-1.3,1-2.5S59,20.5,58.3,20c-0.7-0.5-1.8-0.8-3.3-0.8h-4.6v6.5H55C56.6,25.7,57.7,25.5,58.4,24.9z M58.4,12.6  c0-1.1-0.3-1.9-0.8-2.4c-0.5-0.5-1.4-0.7-2.7-0.7h-4.6v6.4H55C57.3,15.8,58.4,14.7,58.4,12.6z"/>
        <path fill="#004BEF" d="M33.3,12.5c2.9,2.9,2.9,7.5,0,10.4L22.9,33.3c-2.9,2.9-7.5,2.9-10.4,0L2.2,22.9c-2.9-2.9-2.9-7.5,0-10.4  L12.5,2.2c2.9-2.9,7.5-2.9,10.4,0L33.3,12.5z M25.5,20.3c1.4-1.4,1.4-3.8,0-5.2l-5.2-5.2c-1.4-1.4-3.8-1.4-5.2,0l-5.2,5.2  c-1.4,1.4-1.4,3.8,0,5.2l5.2,5.2c1.4,1.4,3.8,1.4,5.2,0L25.5,20.3z"/>
        </svg>
        `}} />
    );
  }
}

export default BlockfiLogo;
